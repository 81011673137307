import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// import { API } from 'aws-amplify'
// import AWSAuth from '@/auth/aws/useAWS'

export default function useTemplatesList() {
  // Use toast
  const toast = useToast()

  const refTemplateListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'templateName', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalTemplates = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('templatename')
  const isBusy = ref(false)
  const isSortDirDesc = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refTemplateListTable.value ? refTemplateListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalTemplates.value,
    }
  })

  const refetchData = () => {
    refTemplateListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchTemplates = (ctx, callback) => {
    console.log(ctx)
    const qvars = {
      forceRefresh: store.state.abilitiestemplates.fetchingAbilitiesTemplatesList,
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
    }
    console.log(qvars)
    store
      .dispatch('app-abilities-templates/fetchTemplates', qvars)
      .then(response => {
        const { templates } = response.data
        callback(templates)
        totalTemplates.value = response.data.totalTemplates
      })
      .catch(error => {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Permissions Templates list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchTemplates,
    tableColumns,
    perPage,
    currentPage,
    totalTemplates,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isBusy,
    isSortDirDesc,
    refTemplateListTable,

    refetchData,
  }
}
